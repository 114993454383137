import { StorageService } from '../services/storage';

function SelectedCenterComponent() {
  const storageService = new StorageService();

  function init() {
    const center = storageService.lab;
    const inHomeResult = storageService.inHomeResult;
    const shouldDisplayComponent = center?.zip_code || inHomeResult;
    const parentContainer = document.querySelector('#labSelectedLocation');
    const iconElement = document.querySelector('#labSelectedLocation i.fa-bolt');

    if (!parentContainer || !shouldDisplayComponent || !iconElement) {
      return;
    }

    removeHomepageFindalabWidget();
    showHomepagePriceAndPackageWidget();
    renderSelectedCenter(center, inHomeResult);
    parentContainer.style.display = 'block';
    iconElement.classList.remove('fa-bolt');
    iconElement.classList.add('fa-map-marker');
  }

  /**
   * Adds survey date field and event listener so admins can copy/paste testimonials from one of our other projects.
   *
   * @param center
   * @param inHomeResult
   */
  function renderSelectedCenter(center, inHomeResult) {
    const selectedCenterDescription = document.querySelector('#selectedCenterDescription');
    const selectedValue = document.querySelector('#selectedCenterValue');
    if (center.zip_code) {
      selectedCenterDescription.textContent = 'Your selected location is';
      selectedValue.textContent = renderCenterDescription(center);
    } else {
      selectedCenterDescription.textContent = 'You selected In-home Collection at';
      selectedValue.textContent = renderInHomeCollectionAddress(inHomeResult.address);
    }
    addChangeLocationLink(selectedValue);
  }

  /**
   * Renders the selected center description. On a small screen only the city, state, and zip code will be displayed (Max. 2 lines).
   * The breakpoint and maximum length are based on when the text wraps to a second line, and on how many characters can fit on two lines in small screens.
   *
   * @param center
   * @returns {string}
   */
  function renderCenterDescription(center) {
    const longText = getLongSelectedCenterDescription(center);
    const MAX_LENGTH = 60;
    const MAX_SCREEN_WIDTH = 880;

    return longText.length > MAX_LENGTH && window.innerWidth < MAX_SCREEN_WIDTH
      ? getShortSelectedCenterDescription(center)
      : longText;
  }

  /**
   * Builds a string with the selected center description containing the street address, city, state and zip code.
   *
   * @param {Object} center A Lab model
   * @returns {string} The long selected center description
   */
  function getLongSelectedCenterDescription(center) {
    return (
      getNetworkName(center) +
      ' at ' +
      center.address +
      (center.address2 ? ' ' + center.address2 : '') +
      ', ' +
      center.city +
      ', ' +
      center.state +
      ' ' +
      center.zip_code
    );
  }

  /**
   * Builds a string with the selected center description containing only city, state and zip code.
   *
   * @param {Object} center A Lab model
   * @returns {string} The short selected center description
   */
  function getShortSelectedCenterDescription(center) {
    return getNetworkName(center) + ' in ' + center.city + ', ' + center.state + ' ' + center.zip_code;
  }

  /**
   * Returns the network name to be displayed as the selected center name, formatting it if necessary.
   *
   * @param {Object} center A Lab model
   * @returns {string} The network name to be displayed
   */
  function getNetworkName(center) {
    switch (center.network.name) {
      case 'Quest':
        return 'Quest Diagnostics';
      default:
        return center.network.name;
    }
  }

  /**
   * Renders the In-Home Collection address description.
   *
   * @param address
   * @returns {string}
   */
  function renderInHomeCollectionAddress(address) {
    return address.street + ', ' + address.city + ', ' + address.state + ' ' + address.zipCode;
  }

  /**
   * Removes the homepage zip code input when the center/In-Home Collection is selected.
   */
  function removeHomepageFindalabWidget() {
    const homepageFindalabWidget = document.querySelector('#home-findalab-widget');

    if (homepageFindalabWidget) {
      homepageFindalabWidget.remove();
    }
  }

  /**
   * Shows the homepage price and package widget when the center/In-Home Collection is selected.
   */
  function showHomepagePriceAndPackageWidget() {
    const homepagePriceAndPackageWidget = document.getElementById('home-price-and-package-widget');

    if (homepagePriceAndPackageWidget) {
      homepagePriceAndPackageWidget.style.setProperty('display', 'block', 'important');
    }
  }

  /**
   * Appends a link to change the selected location to the selected center banner.
   */
  function addChangeLocationLink(siblingElement) {
    const href = `/std-test-center.php`;
    if (!siblingElement || window.location.pathname === href) {
      return;
    }

    const link = document.createElement('a');
    link.href = href;
    link.textContent = ' (change)';
    link.setAttribute('data-qa-id', 'Location Bar Change Button');
    siblingElement.insertAdjacentElement('afterend', link);
  }

  init();
}

window.selectedCenter = new SelectedCenterComponent();
