/**
 * Service class for accessing localStorage.
 */
export function StorageService() {
  /**
   * Keys used to reference data in localStorage
   */
  const CJ_EVENT_KEY = 'ngStorage-cjevent';
  const COUPON_KEY = 'ngStorage-coupon';
  const FEE_KEY = 'ngStorage-fee';
  const LAB_KEY = 'ngStorage-center';
  const REORDER_KEY = 'ngStorage-reorder';
  const TESTS_KEY = 'ngStorage-tests';
  const TOKEN_KEY = 'ngStorage-token';
  const ZIPCODE_KEY = 'ngStorage-zip_code';
  const IN_HOME_KEY = 'ngStorage-in-home-booking-result';
  const EMAIL_KEY = 'ngStorage-email';
  const FREE_KEY = 'ngStorage-free';
  const TRANSACTION_ID_KEY = 'transaction_id';
  const BETTER_LAB = 'ngStorage-betterlab';
  const IHC_PROVIDERS_KEY = 'ngStorage-ihc-providers';

  /**
   * Retrieves a value stored as JSON in localStorage
   *
   * @param {string} key the key to retrieve from storage
   *
   * @return {any}  The value stored in localStorage
   */
  function getLocalStorage(key) {
    return JSON.parse(window.localStorage?.getItem(key) || null);
  }

  /**
   * Saves a value in localStorage as a JSON string
   *
   * @param {string} key The key to store the value in
   * @param {any} value  The value to be stored
   *
   * @return {void}
   */
  function setLocalStorage(key, value) {
    window.localStorage?.setItem(key, JSON.stringify(value || null));
  }

  /**
   * Deletes a value from the storage.
   *
   * @param key {string} The value's key.
   *
   * @return void
   */
  function removeLocalStorage(key) {
    window.localStorage?.removeItem(key);
  }

  // Initialize tests in localStorage
  if (!getLocalStorage(TESTS_KEY)) {
    setLocalStorage(TESTS_KEY, []);
  }

  return {
    /**
     * Local Storage get, set and remove
     */
    localStorage: {
      get: getLocalStorage,
      set: setLocalStorage,
      remove: removeLocalStorage,
    },

    /**
     * Removes any reorder information from local storage
     */
    removeReorderInformation: function () {
      window.localStorage?.removeItem(REORDER_KEY);
    },

    /**
     * Removes previous order information from local storage
     */
    removePreviousOrderInformation: function () {
      window.localStorage?.removeItem(TRANSACTION_ID_KEY);
      window.localStorage?.removeItem(BETTER_LAB);
    },

    /**
     * Retrieve coupon data from local storage
     */
    get coupon() {
      return this.localStorage.get(COUPON_KEY) ?? {};
    },

    /**
     * Store coupon data in local storage
     */
    set coupon(coupon) {
      this.localStorage.set(COUPON_KEY, coupon);
    },

    /**
     * Retrieve fee data from localStorage
     */
    get fee() {
      return this.localStorage.get(FEE_KEY);
    },

    /**
     * Store fee data in localStorage
     */
    set fee(fee) {
      this.localStorage.set(FEE_KEY, fee);
    },

    /**
     * Retrieve lab data from local storage
     */
    get lab() {
      return this.localStorage.get(LAB_KEY) ?? {};
    },

    /**
     * Store lab data in local storage
     */
    set lab(lab) {
      this.localStorage.set(LAB_KEY, lab);
    },

    /**
     * Retrieve psc data from local storage
     */
    get psc() {
      return this.lab;
    },

    /**
     * Store psc data in local storage
     */
    set psc(psc) {
      this.lab = psc;
    },

    /**
     * Retrieve selected tests data from local storage
     */
    get tests() {
      return this.localStorage.get(TESTS_KEY) ?? [];
    },

    /**
     * Store selected tests data in local storage
     */
    set tests(tests) {
      this.localStorage.set(TESTS_KEY, tests);
    },

    /**
     * Retrieve token data from localStorage
     */
    get token() {
      return this.localStorage.get(TOKEN_KEY);
    },

    /**
     * Store token data in localStorage
     */
    set token(token) {
      this.localStorage.set(TOKEN_KEY, token);
    },

    /**
     * Retrieve CJ Affiliate data from localStorage
     */
    get cjevent() {
      return this.localStorage.get(CJ_EVENT_KEY);
    },

    /**
     * Store CJ Affiliate data in localStorage
     */
    set cjevent(cjevent) {
      this.localStorage.set(CJ_EVENT_KEY, cjevent);
    },

    /**
     * Retrieve zip code data from localStorage
     */
    get zipCode() {
      return this.localStorage.get(ZIPCODE_KEY);
    },

    /**
     * Store zip code data in localStorage
     */
    set zipCode(zipCode) {
      this.localStorage.set(ZIPCODE_KEY, zipCode);
    },

    get inHomeResult() {
      return this.localStorage.get(IN_HOME_KEY);
    },

    /**
     * Retrieve email data from local storage
     */
    get email() {
      return this.localStorage.get(EMAIL_KEY) ?? {};
    },

    /**
     * Store email data in local storage
     */
    set email(email) {
      this.localStorage.set(EMAIL_KEY, email);
    },

    /**
     * Retrieve free order data from local storage
     */
    get free() {
      return this.localStorage.get(FREE_KEY) ?? {};
    },

    /**
     * Store free order data in local storage
     */
    set free(free) {
      this.localStorage.set(FREE_KEY, free);
    },

    /**
     * Retrieve In-Home Collection provider IDs from local storage
     */
    get ihcProviders() {
      return this.localStorage.get(IHC_PROVIDERS_KEY);
    },

    /**
     * Store In-Home Collection provider IDs in local storage
     */
    set ihcProviders(ihcProviders) {
      this.localStorage.set(IHC_PROVIDERS_KEY, ihcProviders);
    },
  };
}
